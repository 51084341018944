import DownloadButtons from './DownloadButton'

export default function SixthSection() {
	return (
		<div className='max-w-[60rem]  max-md:px-5 w-full mx-auto  mt-10 md:mt-20 mb-10'>
			<div className='text-grey general-padding text-2xl xmd:text-3xl laptop:text-4xl text-inter font-extrabold mobile:text-center'>
				⚡ Pay for food ⚡Pay for class contribution ⚡Tip a waiter ⚡ Receive
				payment for delivery⚡Pay bus conductor⚡ Make payment for a delivery ⚡
				Pay for bike ride⚡Pay your barber ⚡ Buy drugs ⚡ Pay for gym
				membership ⚡ Pay your tailor ⚡ Buy fuel ⚡ Pay for taxi ⚡ Pay in
				store ⚡ Pay any artisan ⚡ Pay for event tickets ⚡etc. ⚡
			</div>

			<div className='my-10 mt-20'>
				<div className='text-[45px] xmobile:text-[55px] lgMobile:text-[75px] md:text-[90px] laptop:text-[118px] text-center text-knewave text-light-blue leading-none'>
					Be you. Be Free. <br /> Create a Buzzzz!
				</div>
			</div>

			<div className='mt-24'>
				<DownloadButtons />
			</div>
		</div>
	)
}
