export default function DownloadButtons() {
	return (
		<div className=' flex space-x-3 xmobile:space-x-6 lgTablet:space-x-12 justify-center items-center'>
			<button className='!bg-[#2F2F2F] download-btn focus:outline-none focus:ring-none focus:border-none'>
				<img
					src='/google-play-store.png'
					alt='QQpay'
					className='lgMobile:w-7 lgMobile:h-7 w-5 h-5'
				/>
				<div className='text-left grid ml-3'>
					<div className='uppercase font-light text-[9px] mobile:text-xs'>
						Get it on
					</div>
					<div className='-mt-[6px] font-medium text-sm '>Google Play</div>
				</div>
			</button>

			<button className='!bg-[#2F2F2F] download-btn focus:outline-none focus:ring-none focus:border-none'>
				<img
					src='/apple-logo.png'
					alt='QQpay'
					className='lgMobile:w-7 lgMobile:h-7 w-5 h-5'
				/>
				<div className='text-left grid ml-3'>
					<div className='uppercase font-light text-[9px] mobile:text-xs'>
						Get it on
					</div>
					<div className='-mt-[6px] font-medium text-sm '>Apple Store</div>
				</div>
			</button>
		</div>
	)
}
