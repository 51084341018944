import DownloadButtons from './DownloadButton';

export default function Hero() {
  return (
    <div className='relative w-full'>
      <div className='relative w-full max-w-[60rem]  mx-auto h-fit'>
        <div className='mt-10 md:mt-20'>
          <p className='text-knewave text-xl mx-auto max-xmobile:w-52 lgMobile:text-2xl my-3 lgMobile:my-5 text-center'>
            Increasing the velocity of money ...
          </p>
          <div className='relative mt-2 md:mt-5'>
            <div className='text-agrandir-bold text-[75px] xmobile:text-[90px] lgMobile:text-[120px] md:text-[150px] laptop:text-[175px] leading-[0.9]  text-center font-bold'>
              BUZZ <br />
              ME
            </div>
            <img
              src='/heroMockup.png'
              alt='BuzzMe Landing page '
              className=' mx-auto tablet:mr-52 laptop:mr-32 -mt-[40px] md:-mt-[105px] object-fill object-center w-[20rem] md:w-[26rem] laptop:w-[33rem]  '
            />

            <div className='w-full -mt-10  '>
              <DownloadButtons />
            </div>
          </div>
        </div>
        <div className='md:block hidden w-[50rem]  lgTablet:w-[60rem] lgLaptop:w-[65rem]  desktop:w-[70rem]  top-0 left-1/2 -translate-x-1/2 absolute  mx-auto h-full  '>
          {/* Gift Box  */}
          <img
            src='/hero/heroGiftBox.png'
            alt='BuzzMe Landing page '
            className=' absolute w-32 lgTablet:w-52 desktop:w-60 left-10 laptop:-left-[50px] desktop:-left-[150px] top-1/4 -translate-y-1/2 object-fill object-center  '
          />

          {/* LightBulb  */}
          <img
            src='/hero/heroLightBulb.png'
            alt='BuzzMe Landing page '
            className='w-32 lgTablet:w-52 desktop:w-60 absolute right-0 lgTablet:right-4 lgDesktop:-right-12 top-[15%] -translate-y-1/2 object-fill object-center  '
          />

          {/* Rocket  */}
          <img
            src='/hero/heroRocket.png'
            alt='BuzzMe Landing page '
            className='w-32 lgTablet:w-52 desktop:w-60 absolute right-0 lgTablet:right-4 lgDesktop:-right-12 bottom-1/4  object-fill object-center  '
          />
        </div>
      </div>

      {/* Graph image  */}
      <img
        src='/hero/heroGraph.png'
        alt='BuzzMe Landing page '
        className='md:block hidden absolute left-0 bottom-0 object-fill object-center w-[20rem] laptop:w-[30rem] desktop:w-[38rem]  '
      />
    </div>
  );
}
