import { IoScanOutline } from 'react-icons/io5'
import { FiCheckCircle } from 'react-icons/fi'

export default function ThirdSection() {
	return (
		<div className='max-w-[60rem] max-md:px-5  mx-auto grid grid-cols-1 md:grid-cols-2 mt-10 md:mt-20 mb-10'>
			<img
				src='/phone-mockup.png'
				alt='QQpay'
				className='w-[80%] mx-auto md:w-full '
			/>
			<div className='flex max-md:order-last max-md:space-y-14 max-md:mt-4 flex-col items-start px-0 md:px-16 pb-5 justify-center space-y-14'>
				<div className='flex space-x-4'>
					<div>
						<IoScanOutline className=' h-6 w-6' />
					</div>
					<div>
						<p className='text-lg -mt-1 font-medium '>Scan to Pay</p>
						<div className='mt-2'>
							Scan a QR code on any partner app to pay or Display your QR to
							collect your money seamlessly & instantly
						</div>
					</div>
				</div>

				{/* <div className='flex space-x-4'>
						<div>
							<FiRss className=' h-6 w-6' />
						</div>
						<div>
							<p className='text-lg -mt-1 font-medium '>Tap to Pay</p>
							<div className='mt-2'>
								Input your amount & bring your NFC enabled device close to
								collect your money seamlessly
							</div>
						</div>
					</div> */}

				<div className='flex space-x-4'>
					<div>
						<FiCheckCircle className=' h-6 w-6' />
					</div>
					<div>
						<p className='text-lg -mt-1 font-medium '>Collect Your Money</p>
						<div className='mt-2'>
							Be in control, easily input amount & choose how to get paid. 99.9%
							success rate with instant alert.No downtime, no delays!
						</div>
					</div>
				</div>
			</div>
			{/* <div className='h-[580px] tablet:h-[625px] '> */}

			{/* </div> */}
		</div>
	)
}
