import { PiSpinnerBold } from 'react-icons/pi';

export default function SupportPage() {
	return (
		<div className='text-center bg-white h-screen'>
			<div className='flex justify-center py-10'>
				<span className='animate-spin '>
					<PiSpinnerBold size={50} />
				</span>
			</div>
		</div>
	);
}
