export default function Footer() {
  return (
    <div className='w-full py-5 md:py-12  text-white'>
      <div className='max-w-[95rem] mx-auto w-full general-padding'>
        <div className='mt-10 mb-10 md:mb-6 tablet:mb-12 flex  max-md:flex-col justify-between max-md:items-center '>
          <div className='max-md:mt-14 max-md:order-2 flex max-md:flex-col md:space-x-8'>
            <div className='text-center flex flex-col gap-1'>
              <div className='text-xs'>... powered by</div>
              <img
                src='/whiteLogo.png'
                alt='El-buk logo'
                className='w-32 mobile:w-44 md:w-32 tablet:w-40 laptop:w-50 '
              />
            </div>
          </div>

          <div className='max-md:order-1 text-center max-mobile:text-sm max-md:text-base max-tablet:text-sm '>
            <p className='  max-w-[480px] lgLaptop:max-w-[600px] font-light'>
              QQPay & Buzzme Technologies is a duly registered entity with the
              CAC, operating as a subsidiary of Tribonini Enterprises. Wallet &
              Payment Processing Services are provided by Paystack Technologies
              Ltd.
            </p>

            <p className='mt-6'>Email: support@qqpay-team.freshworksmail.com</p>
            {/* <p className='mt-1'>
              Address: o/c 41, Ruby Close, Brains & Hammers, FCT, Abuja
            </p> */}
          </div>
          <div className=' max-md:mt-14 max-md:order-3 flex max-md:flex-col md:space-x-8'>
            <div className='text-center flex flex-col gap-1'>
              <div className='text-xs'>... built on</div>
              <img
                src='/paystackLogo.png'
                alt='El-buk logo'
                className=' max-mobile:w-24 max-md:w-36 '
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
