export default function BreakFreeSection() {
	return (
		<div className='general-padding w-full mt-12 max-w-[28rem] lgMobile:max-w-[35rem] md:max-w-[50rem] laptop:max-w-[70rem]  mx-auto'>
			<div className='text-[45px] xmobile:text-[55px] lgMobile:text-[75px] md:text-[90px] laptop:text-[118px] text-knewave'>
				<div className='text-light-blue leading-none text-left  tracking-wide mb-5'>
					Break free <br /> from the <br /> norm!
				</div>
				<div className='text-yellow -rotate-[7.5deg] leading-none text-right tracking-wide'>
					Create a <br /> Buzzz... <br /> Always!
				</div>
			</div>

			<div className='mt-12  font-semibold max-mobile:flex-col text-2xl mobile:text-xl lgMobile:text-2xl md:text-3xl laptop:text-4xl text-inter text-white flex gap-1 mobile:justify-center mobile:items-center'>
				<div className='whitespace-nowrap'>Sign up . </div>
				<div className='whitespace-nowrap'>Get Your QR .</div>
				<div className='whitespace-nowrap'>Collect Your Money</div>
			</div>
		</div>
	)
}
