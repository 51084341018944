import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
// import { navLinks } from '../../constants/navLinks'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useEffect, useState } from 'react'

export const navLinks = [
	{
		label: 'Blog',
		url: 'https://medium.com/@hi.qqpay',
	},
	{
		label: 'Download',
		url: '',
	},
]

export default function Navbar() {
	// const location = useLocation()

	const [sideMenu, setSideMenu] = useState(false)

	useEffect(() => {
		// Add/remove class to body when sideMenu is opened/closed
		const body = document.body
		if (sideMenu) {
			body.classList.add('no-scroll')
		} else {
			body.classList.remove('no-scroll')
		}

		// Cleanup on component unmount
		return () => {
			body.classList.remove('no-scroll')
		}
	}, [sideMenu])

	return (
		<div className='w-full h-fit text-white sticky top-0  z-50  py-3'>
			<div className='max-w-[95rem] mx-auto w-full general-padding'>
				<div className='flex items-center w-full justify-between py-2 '>
					<div className='max-width w-full  flex items-center justify-between'>
						<img
							src='/logo.png'
							alt='El-buk logo'
							className='w-24 md:w-32 '
						/>

						<div className='hidden tablet:flex items-center space-x-7'>
							{navLinks.map((link, idx) => {
								return (
									<Link
										key={idx}
										to={link.url || '/'}
										target={link.url && `_blank`}
										className={`font-medium hover:opacity-75`}
									>
										{link.label}
									</Link>
								)
							})}
						</div>

						<GiHamburgerMenu
							onClick={() => setSideMenu(true)}
							className='text-2xl md:hidden block'
						/>
					</div>
				</div>

				{sideMenu && (
					<div
						onClick={() => setSideMenu(false)}
						className=' fixed h-full left-0 top-0 w-full '
					>
						<div
							onClick={(e) => e.stopPropagation()}
							className='h-fit pt-2 pb-4  px-3 bg-black bg-opacity-40 backdrop-blur-sm  shadow-lg absolute right-[2px] top-16 rounded-l'
						>
							<div
								className='flex justify-end w-full  mb-[6px]'
								onClick={() => setSideMenu(false)}
							>
								<MdClose className='text-xl -mr-2' />
							</div>
							<div className='grid gap-3 pr-3'>
								{navLinks.map((link, idx) => {
									return (
										<Link
											key={idx}
											to={link.url || '/'}
											target={link.url && `_blank`}
											onClick={() => setSideMenu(false)}
											className={`mobile:text-lg pb-1 px-2 font-medium ${
												navLinks?.length - 1 !== idx && 'border-b'
											}  hover:text-primary `}
										>
											{link.label}
										</Link>
									)
								})}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
