import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'

export default function GeneralLayout() {
	return (
		<div className=' w-full relative '>
			<img
				src='/lines.png'
				alt='BuzzMe Landing page '
				className=' absolute left-[45%] -translate-x-1/2 top-3 object-fill object-center w-full h-[110vh] '
			/>
			<Navbar />
			<main className='  mx-auto py-4 tablet:py-7 '>
				<Outlet />
			</main>
			<Footer />
		</div>
	)
}
