import DownloadButtons from './DownloadButton'

export default function FourthSection() {
	return (
		<div className='max-w-[60rem] max-md:px-5  mx-auto  mt-10 md:mt-20 mb-10'>
			<div className='mt-6'>
				<DownloadButtons />
			</div>

			<div className='mt-12  font-semibold text-2xl mobile:text-xl lgMobile:text-2xl md:text-3xl laptop:text-4xl text-inter text-white text-center'>
				Sign up & Pay anyone instantly.
			</div>

			<div className='mt-12 text-yellow text-[45px] xmobile:text-[55px] lgMobile:text-[75px] md:text-[90px] laptop:text-[118px] text-knewave -rotate-[7.5deg] leading-none mobile:text-center tracking-wide'>
				<div className=' text-left relative'>
					<span className='opacity-50'>Pay</span>
					<img
						src='/lineVector.png'
						alt='QQpay'
						className='max-lgMobile:w-[8rem] max-md:w-[13rem] max-laptop:w-[16rem] absolute top-[60%] -translate-y-1/2 -translate-x-[15%] lgMobile:-translate-x-[20%] left-0 z-10 rotate-[9deg] '
					/>
				</div>
				<div>
					BuzzzZ for <br /> anything, <br /> anywhere!!
				</div>
			</div>
		</div>
	)
}
