import Hero from '../components/Hero'
import BreakFreeSection from '../components/BreakFreeSection'
import ThirdSection from '../components/ThirdSection'
import FourthSection from '../components/FourthSection'
import FifthSection from '../components/FifthSection'
import SixthSection from '../components/SixthSection'

export default function Homepage() {
	return (
		<div className='grid text-white gap-5 mobile:gap-8 tablet:gap-12'>
			<Hero />

			<BreakFreeSection />

			<ThirdSection />

			<FourthSection />

			<FifthSection />

			<SixthSection />
		</div>
	)
}
