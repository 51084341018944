export default function FifthSection() {
	return (
		<div className='max-w-[60rem] general-padding max-md:px-5 w-full mx-auto  mt-10 md:mt-20 mb-10'>
			<div className='grid max-xmd:place-content-center xmd:grid-rows-4 xmd:grid-flow-col gap-4 w-full text-4xl tablet:text-5xl text-knewave '>
				{/* Grid item 1 */}
				<div className=' rounded-[42px] max-mobile:w-full max-lgMobile:w-[90%] max-xmd:w-[70%] mx-auto w-full  flex justify-center min-w-full xmd:min-w-[9rem] tablet:min-w-[15rem] h-[30rem] mobile:h-[35rem] lgMobile:h-[41rem] xmd:row-span-4 relative'>
					<img
						src='/splitBill.png'
						alt='Split bills with friends'
						className='rounded-[42px] text-center h-full'
					/>
					<div className='text-yellow text-shadow -rotate-[7.5deg] py-4 px-1 leading-none absolute bottom-7 mobile:bottom-16 left-1/2 xmd:left-0 max-xmd:-translate-x-1/2 '>
						Split bills with friends
					</div>
				</div>

				{/* Grid item 2 */}
				<div className=' rounded-[42px] h-[15rem] mobile:h-[20rem] max-mobile:w-full max-lgMobile:w-[90%] max-xmd:w-[70%] mx-auto xmd:row-span-2 xmd:col-span-2 relative'>
					<img
						src='/payRide.png'
						alt='Pay for a ride'
						className='rounded-[42px] h-full w-full object-fill'
					/>
					<div className='text-yellow text-shadow -rotate-[7.5deg] py-4 px-4 leading-none absolute top-10 left-0 !md:bottom-10 !md:right-0  '>
						Pay for a ride
					</div>
				</div>

				{/* Grid item 3 */}
				<div className=' rounded-[42px] h-[15rem] mobile:h-[20rem] max-mobile:w-full max-lgMobile:w-[90%] max-xmd:w-[70%] mx-auto xmd:row-span-2 xmd:col-span-2 relative'>
					<img
						src='groceries.png'
						alt='Buy groceries'
						className='rounded-[42px] h-full'
					/>
					<div className='text-yellow text-shadow -rotate-[7.5deg] py-4 w-full text-center leading-none absolute bottom-24 left-1/2 -translate-x-1/2 '>
						Buy groceries
					</div>
				</div>
			</div>
		</div>
	)
}
